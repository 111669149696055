import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FromSchema } from 'json-schema-to-ts';

import { UserDocument, userDocumentSchema } from '../../types';
import { ROLES } from '../../constants';
import { RootState } from '../../stores/AppStore';
import { getPersistedState } from '../../services/LocalStorageService';
import { apiSlice } from '../../services/apiSlice';

const userStateSchema = {
  type: 'object',
  properties: {
    token: { type: 'string' },
    user: userDocumentSchema,
  },
  required: [],
  additionalProperties: false,
} as const;

export type UserState = FromSchema<typeof userStateSchema>;

const initialState = getPersistedState<UserState>('user', userStateSchema) ?? {
  token: undefined,
  user: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = undefined;
      state.user = undefined;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<UserDocument>) => {
      state.user = action.payload;
    },
    setLastQuizReviewFinished: (state, action: PayloadAction<boolean>) => {
      if (
        !state.user ||
        !state.user.reviewedQuizzes ||
        state.user.reviewedQuizzes.length === 0
      ) {
        return state;
      }
      const lastIndex = state.user.reviewedQuizzes.length - 1;
      const updatedUser = {
        ...state.user,
        reviewedQuizzes: [...state.user.reviewedQuizzes],
      };
      updatedUser.reviewedQuizzes[lastIndex] = {
        ...updatedUser.reviewedQuizzes[lastIndex],
        didFinishReview: action.payload,
      };
      return {
        ...state,
        user: updatedUser,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        apiSlice.endpoints.finalizeQuizSubmission.matchFulfilled,
        (state, { payload }) => {
          if (!state.user) {
            return;
          }
          return {
            ...state,
            user: {
              ...state.user,
              reviewedQuizzes: [
                ...(<[]>state.user?.reviewedQuizzes),
                {
                  id: payload.quiz.id,
                  didFinishReview: false,
                },
              ],
            },
          };
        },
      )
      .addMatcher(
        apiSlice.endpoints.updateReviewProgress.matchFulfilled,
        (state, { payload }) => {
          if (!state.user) {
            return;
          }
          return {
            ...state,
            user: {
              ...state.user,
              reviewedQuizzes: payload.reviewedQuizzes,
            },
          };
        },
      )
      .addMatcher(
        apiSlice.endpoints.getMyUser.matchFulfilled,
        (state, { payload }) => {
          if (!state.user) {
            return;
          }
          return {
            ...state,
            user: payload,
          };
        },
      );
  },
});

export const { logout, setToken, setUser, setLastQuizReviewFinished } =
  userSlice.actions;

export const selectIsAuthenticated = (state: RootState) => !!state.user.token;

export const selectUserId = (state: RootState) => state.user.user?._id;

export const selectHasOneOfTheRoles = (
  state: RootState,
  expectedRoles: ROLES[],
) => {
  const { user } = state.user;
  if (!Array.isArray(user?.roles)) {
    return false;
  }
  return expectedRoles.some((role) => user?.roles.includes(role));
};

export const getDefaultPagePath = (state: RootState) => {
  if (selectHasOneOfTheRoles(state, [ROLES.student, ROLES.thinker])) {
    return '/summary';
  } else if (selectHasOneOfTheRoles(state, [ROLES.teacher])) {
    return `/teacher/student-scores`;
  } else if (selectHasOneOfTheRoles(state, [ROLES.mentor])) {
    return `/mentor/thinkers`;
  } else if (selectHasOneOfTheRoles(state, [ROLES.schooladmin])) {
    return `/schooladmin/teacher-scores`;
  } else if (selectHasOneOfTheRoles(state, [ROLES.sysadmin, ROLES.owner])) {
    return `/users`;
  } else {
    return '/login';
  }
};

export const selectTasks = (state: RootState) =>
  (state.user.user?.tasks || []).filter((task) => task.status === 'todo');

export default userSlice.reducer;
