import Vimeo from '@u-wave/react-vimeo';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Player } from '@vimeo/player';

interface AboutProperties {
  onGetStarted?: () => void;
}

export default function About({ onGetStarted }: AboutProperties) {
  const [isSticky, setIsSticky] = useState(true);
  const landingVideoUrl =
    'https://player.vimeo.com/video/1011093599?h=b93bac9061';

  const playerReference = useRef<Player | null>(null);
  const onReady = (player: Player) => {
    playerReference.current = player;
  };

  useEffect(() => {
    const handleScroll = () => {
      const targetDiv = document.querySelector('#continue-button-div');
      if (targetDiv) {
        const targetDivTop = targetDiv.getBoundingClientRect().top;
        setIsSticky(targetDivTop > window.innerHeight);
      }
    };

    document.body.addEventListener('scroll', handleScroll);
    return () => document.body.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div data-testid="start-sign-up">
      <div className={`flex flex-col gap-6 ${onGetStarted ? 'p-4' : ''}`}>
        <div className="bg-blue-100 text-tttDefault py-2 px-4 w-max rounded-full uppercase text-sm md:text-base">
          About the program
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 xl:gap-12">
          <h1 className="text-3xl md:text-4xl font-medium">
            It's easy to get started <br /> with the Fill the Math
            <br /> GAPS program.
          </h1>
          <div className="flex flex-col gap-4 md:gap-6 text-gray-600">
            <p>
              By signing up as a parent mentor, you're taking the first step in
              empowering your child to become an independent thinker and a
              confident problem solver.
            </p>
            <p>
              Let's get started on this exciting journey together to unlock your
              child's full potential in math!
            </p>
          </div>
        </div>

        {/* Features */}
        <div
          className="
            grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 p-4 md:p-6 
            bg-gray-100 rounded-xl
            font-medium text-center
          "
        >
          <div className="flex flex-row md:flex-col gap-2 md:gap-4 items-center justify-start text-start md:text-center">
            <img
              src={'/images/quiz.svg'}
              alt="Quiz Icon"
              className="h-14 basis-1/4"
            />
            <p className="basis-3/4 whitespace-pre-line">
              Comprehensive <br /> Quizzes
            </p>
          </div>
          <div className="flex flex-row md:flex-col gap-2 md:gap-4 items-center justify-start text-start md:text-center">
            <img
              src={'/images/video.svg'}
              alt="Video Icon"
              className="h-14 basis-1/4"
            />
            <p className="basis-3/4">
              Captivating <br /> Educational Videos
            </p>
          </div>

          <div className="flex flex-row md:flex-col gap-2 md:gap-4 items-center justify-start text-start md:text-center">
            <img
              src={'/images/icons/explanation.svg'}
              alt="Explanation Icon"
              className="h-14 basis-1/4"
            />
            <p className="basis-3/4">
              Clear and Straightforward <br />
              Explanations
            </p>
          </div>
          <div className="flex flex-row md:flex-col gap-2 md:gap-4 items-center justify-start text-start md:text-center">
            <img
              src={'/images/ui.svg'}
              alt="UI Icon"
              className="h-14 basis-1/4"
            />
            <p className="basis-3/4">
              User-Friendly Interface for <br />
              Both Mentors and Thinkers
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 justify-items-center items-center text-center">
          {/* paddingTop: '56.25%' --> 16:9 Aspect Ratio */}
          <div
            className="relative w-full h-0 rounded-xl overflow-hidden"
            style={{ paddingBottom: '56.25%' }}
          >
            <Vimeo
              video={landingVideoUrl}
              responsive
              className="absolute top-0 left-0 w-full h-full"
              controls={true}
              autoplay={true}
              onReady={onReady}
              onLoaded={async () => {
                const intervalReference = setInterval(async () => {
                  const isPaused = await playerReference.current?.getPaused();
                  if (!isPaused) {
                    clearInterval(intervalReference);
                    return;
                  }
                  // Try to autoplay after the first interaction
                  if ((navigator as any)?.userActivation?.hasBeenActive) {
                    playerReference.current?.play();
                  }
                }, 1000);
              }}
            />
          </div>
          <div className="flex flex-col gap-2 xl:gap-4 mx-w-[80%] xl:max-w-[70%] mx-auto font-medium">
            <p className="text-xl mb-4">
              Ready to Unlock Your Child's Potential?
            </p>
            {/* Fixed Button */}
            {typeof onGetStarted === 'function' ? (
              <button
                id="continue-button-div"
                data-testid={`continue-button`}
                onClick={onGetStarted}
                className={`
              md:opacity-100
              ${isSticky ? 'opacity-0' : 'opacity-100'}
              bg-tttDefault text-white py-2 xl:py-3 px-2 xl:px-4 rounded-full
              hover:shadow-md transition-all duration-500 ease-in-out hover:bg-opacity-90
              `}
              >
                Get Started
              </button>
            ) : (
              <Link to="/sign-up?skipAbout" target="_self">
                <button
                  id="continue-button-div"
                  data-testid={`continue-button`}
                  className={`
              md:opacity-100
              ${isSticky ? 'opacity-0' : 'opacity-100'}
              bg-tttDefault text-white py-2 xl:py-3 px-2 xl:px-4 rounded-full
              hover:shadow-md transition-all duration-500 ease-in-out hover:bg-opacity-90
              `}
                >
                  Get Started
                </button>
              </Link>
            )}
          </div>
          {/* paddingTop: '56.25%' --> 16:9 Aspect Ratio */}
          <div
            className="relative w-full overflow-hidden"
            style={{ paddingTop: '56.25%' }}
          >
            <img
              className="absolute top-0 left-0 w-full h-full object-cover rounded-xl row-start-3 md:row-start-1 md:col-start-3"
              src="images/mentor-and-thinker.png"
              alt="Thinker Studying"
            />
          </div>
        </div>
      </div>

      {/* Mobile Floating Button */}
      {typeof onGetStarted === 'function' ? (
        <button
          data-testid={`floating-continue-button`}
          onClick={onGetStarted}
          className={`
          md:hidden
          ${
            isSticky ? 'fixed bottom-8 left-4 right-4 opacity-100' : 'opacity-0'
          }
          bg-tttDefault text-white font-medium py-2 xl:py-3 px-2 xl:px-4 rounded-full
          hover:shadow-md transition-all duration-500 ease-in-out hover:bg-opacity-95
        `}
        >
          Get Started
        </button>
      ) : (
        <Link to="/sign-up?skipAbout" target="_self">
          <button
            data-testid={`floating-continue-button`}
            className={`
          md:hidden
          ${
            isSticky ? 'fixed bottom-8 left-4 right-4 opacity-100' : 'opacity-0'
          }
          bg-tttDefault text-white font-medium py-2 xl:py-3 px-2 xl:px-4 rounded-full
          hover:shadow-md transition-all duration-500 ease-in-out hover:bg-opacity-95
        `}
          >
            Get Started
          </button>
        </Link>
      )}
    </div>
  );
}
