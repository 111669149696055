import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react';
import { push } from 'redux-first-history';
import * as Sentry from '@sentry/react';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';
import { Address } from '@stripe/stripe-js';

import {
  AppState,
  ClassDocument,
  ClassSubjectResult,
  ClassResultOverview,
  ClassTotal,
  ExtendedSkillResult,
  ExtendedStudentResult,
  ExtendedSubjectResult,
  ExtendedTrack,
  ImportDocument,
  ImportStats,
  LoginCredentials,
  LoginResponse,
  PasswordChangeParameters,
  PasswordResetParameters,
  ProblemSkills,
  Quiz,
  QuizAnswerPayload,
  QuizResult,
  QuizStats,
  ResultDocument,
  SchoolResultOverview,
  SchoolTrack,
  SkillResult,
  StudentResult,
  StudentResultOverview,
  StudentTrackWithSchedule,
  SubjectResult,
  SubjectSkillResults,
  SubmitPracticeResponse,
  SubmittedQuiz,
  TeacherResult,
  TrackDocument,
  Tutorial,
  TutorialsResponse,
  UserDocument,
  ProblemDocument,
  GetUsersListParameters,
  TableDataResponse,
  UsersListItem,
  AssignTargetedPracticesPayload,
  TargetedPracticeAssignListItem,
  AssignedTargetedPracticeListItem,
  TargetedPractice,
  TargetedPracticeResult,
  AddThinkerFormData,
  TargetedPracticeProblemDocument,
  SignupTrack,
  StudentQuizStats,
  UserMetrics,
  UserSettings,
} from '../types';
import { ImportStatus, ROLES, Subject } from '../constants';
import {
  getDefaultPagePath,
  logout,
  setLastQuizReviewFinished,
  setToken,
  setUser,
} from '../features/user/userSlice';
import {
  setAppState,
  setClientVersion,
  setImportDetails,
} from '../features/global/globalSlice';
import { RootState, store } from '../stores/AppStore';
import { rootStore } from '../stores/RootStore';
import { SignUpState } from '../features/signUp/signUpSlice';

const retryingBaseQuery = (
  arguments_: string | FetchArgs,
  api: BaseQueryApi,
  _extraOptions: any,
) => {
  const isUnrecoverableFailure =
    typeof arguments_ !== 'string' &&
    [
      '/auth',
      '/users/password-reset',
      '/users/change-password',
      '/users/set-password',
    ].includes(arguments_?.url);
  return retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL + '/',
      prepareHeaders: (headers, { getState }) => {
        const { clientVersion } = (getState() as any).global;
        const { token } = (getState() as any).user;
        if (token) {
          headers.set('authorization', `Bearer ${token}`);
          headers.set('X-Client-Version', clientVersion);
        }
      },
      validateStatus: (response: Response, body: any) => {
        const url = response.url;
        const status = response.status;
        if (status === 401 && !/auth/gi.test(url)) {
          toast('Your token has expired. Please log in again.', {
            id: 'token-expired',
          });
          setTimeout(() => {
            store.dispatch(logout());
            store.dispatch(apiSlice.util.resetApiState());
          }, 1500);
          return false;
        }
        const message = body?.message;
        if (status === 401 && /auth/gi.test(url) && message) {
          toast.error(`Error: ${message}`);
          return false;
        }
        if (response.status === 418) {
          store.dispatch(apiSlice.util.invalidateTags(['ClientVersion']));
        }
        return response.status >= 200 && response.status <= 299;
      },
    }),
    {
      retryCondition: (error, arguments__, extraArguments) => {
        if (
          error.status === 400 ||
          error.status === 401 ||
          error.status === 403 ||
          error.status === 404 ||
          (error as any).originalStatus === 429 // Too many requests
        ) {
          return false;
        }
        const maxAttempts = isUnrecoverableFailure ? 1 : 6; // 0 and 5 retries
        if (extraArguments.attempt <= maxAttempts) {
          return true;
        }
        return false;
      },
    },
  )(arguments_, api, _extraOptions);
};

export const apiSlice = createApi({
  keepUnusedDataFor: 10 * 60, // seconds - after data hasn't been accessed for this long, the cache will be deleted
  refetchOnMountOrArgChange: 30, // seconds - data is considered stale after this and will be refetched while the stale data is still available from the cache
  baseQuery: retryingBaseQuery,
  tagTypes: [
    'User',
    'ClientVersion',
    'Track',
    'Quiz',
    'Result',
    'Class',
    'Score',
    'ImportHistory',
    'ImportDetails',
    'UsersList',
    'StudentOrThinkerSummary',
  ], // TODO: rethink
  endpoints: (builder) => ({
    getClientVersion: builder.query<{ version: string }, void>({
      query: () => ({
        url: '/client-version',
      }),
      providesTags: ['ClientVersion'],
      async onQueryStarted(arguments_, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (
            !!data.version &&
            data.version !== (getState() as RootState).global.clientVersion
          ) {
            dispatch(setClientVersion(data.version));
            dispatch(apiSlice.util.prefetch('getMyUser', undefined, {}));
            // FIXME: remove legacy MobX store update
            rootStore.uiStore.setClientVersionLoggedAt('');
            window.location.reload();
          }
        } catch (error) {
          console.error('error at getClientVersion', error);
        }
      },
      keepUnusedDataFor: 60 * 60,
    }),
    sendErrorContext: builder.mutation<unknown, unknown>({
      async queryFn(_argument, queryApi, _extraOptions, fetchWithBQ) {
        return await fetchWithBQ({
          url: '/stats/error',
          method: 'POST',
          body: JSON.stringify(queryApi.getState()),
        });
      },
      async onQueryStarted(arguments_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          Sentry.captureException(error, {
            tags: {
              request: 'sendErrorContext',
            },
          });
          toast.error(
            'Failed to submit bug report automatically. Please let us know that something went wrong.',
          );
        }
      },
    }),
    login: builder.mutation<LoginResponse, LoginCredentials>({
      query: (credentials) => ({
        url: '/auth',
        method: 'POST',
        body: credentials,
      }),
      async onQueryStarted(arguments_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(apiSlice.util.resetApiState());
          dispatch(setToken(data.access_token));
          dispatch(setClientVersion(data.clientVersion));
          dispatch(setAppState(AppState.justAuthenticated));
          dispatch(
            apiSlice.util.prefetch('getMyUser', undefined, { force: true }),
          );
        } catch (error) {
          console.error('error at login', error);
        }
      },
    }),
    switchUser: builder.mutation<LoginResponse, string>({
      query: (userId) => ({
        url: '/users/switch-user',
        method: 'POST',
        body: {
          userId,
        },
      }),
      async onQueryStarted(arguments_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          apiSlice.util.resetApiState();
          toast.success('Successful user switch');
          dispatch(setToken(data.access_token));
          dispatch(setClientVersion(data.clientVersion));
          dispatch(setAppState(AppState.justAuthenticated));
          dispatch(
            apiSlice.util.prefetch('getMyUser', undefined, { force: true }),
          );
        } catch (error) {
          toast.error('Failed to switch user');
          console.error('error at user switch', error);
        }
      },
    }),
    changePassword: builder.mutation<void, PasswordChangeParameters>({
      query: (passwordChangeParameters) => ({
        url: '/users/change-password',
        method: 'POST',
        body: {
          password: passwordChangeParameters.newPassword,
          confirm: passwordChangeParameters.confirm,
          oldPassword: passwordChangeParameters.currentPassword,
        },
      }),
    }),
    sendPasswordResetLink: builder.mutation<void, string>({
      query: (email) => ({
        url: '/users/password-reset',
        method: 'POST',
        body: { email },
      }),
    }),
    getUserByToken: builder.query<UserDocument, string>({
      query: (token) => ({
        url: `/users/token/${token}`,
      }),
      async onQueryStarted(arguments_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {
          console.error('error at getUserByToken', error);
        }
      },
    }),
    resetPassword: builder.mutation<
      void,
      PasswordResetParameters & { token: string }
    >({
      query: (passwordResetParameters) => ({
        url: '/users/set-password',
        method: 'POST',
        body: {
          password: passwordResetParameters.password,
          confirm: passwordResetParameters.confirm,
          token: passwordResetParameters.token,
        },
      }),
    }),
    updatePasswordForUser: builder.mutation<
      UserDocument,
      { userId: string; password: string }
    >({
      query: ({ userId, password }) => ({
        url: '/users/owner/change-password',
        method: 'POST',
        body: { userId, password },
      }),
    }),
    getMyUser: builder.query<UserDocument, void>({
      providesTags: ['User'],
      query: () => ({
        url: '/users/me',
      }),
      async onQueryStarted(arguments_, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { appState } = (getState() as RootState).global;
          dispatch(setUser(data));
          if (appState === AppState.justAuthenticated) {
            const defaultPagePath = getDefaultPagePath(getState() as RootState);
            dispatch(push(defaultPagePath));
            dispatch(setAppState(AppState.loaded));
          }
        } catch (error) {
          if ((error as any)?.error?.status === 404) {
            toast(
              'Your account is not available at the moment. Please try to sign in again or contact us.',
              {
                id: 'user-not-found',
              },
            );
            setTimeout(() => {
              store.dispatch(logout());
              store.dispatch(apiSlice.util.resetApiState());
            }, 1500);
            return;
          }
          console.error('error at getMyUser', error);
        }
      },
    }),
    updateEmailForUser: builder.mutation<
      string, // new email
      { userId: string; email: string }
    >({
      query: ({ userId, email }) => ({
        url: '/users/mentor/change-email',
        method: 'POST',
        body: { userId, email },
      }),
      invalidatesTags: ['StudentOrThinkerSummary'],
    }),
    updateUserMetricsSettings: builder.mutation<
      void,
      { metrics: UserMetrics; settings: UserSettings }
    >({
      query: ({ metrics, settings }) => ({
        url: '/users/metrics-settings',
        method: 'POST',
        body: { metrics, settings },
      }),
    }),
    getStudentOrThinkerSummaryData: builder.query<
      | StudentResultOverview
      | (StudentTrackWithSchedule & StudentResultOverview),
      { studentId?: string; shouldFetchTrack?: boolean }
    >({
      async queryFn(
        { studentId, shouldFetchTrack = true },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const responses = await Promise.all([
          fetchWithBQ({
            url: `/stats/student/overview`,
            params: { studentId },
          }),
          shouldFetchTrack
            ? fetchWithBQ({
                url: `/tracks/student/${studentId}`,
              })
            : undefined,
        ]);

        if (responses[0].error) {
          return { error: responses[0].error };
        }

        const result = {
          data: {
            ...(responses[0].data as StudentResultOverview),
          },
        };
        if (responses[1]) {
          result.data = {
            ...result.data,
            ...(responses[1].data as StudentTrackWithSchedule),
          };
        }
        return result;
      },
      providesTags: ['StudentOrThinkerSummary'],
    }),
    getStudentOrThinkerGAPSResults: builder.query<
      SubjectSkillResults & StudentResultOverview,
      { userId: string; subject: string }
    >({
      async queryFn(
        { userId, subject },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const responses = await Promise.all([
          fetchWithBQ({
            url: `/stats/student/breakout/${subject}`,
            params: { studentId: userId },
          }),
          fetchWithBQ({
            url: `/stats/student/overview`,
            params: { studentId: userId },
          }),
        ]);
        for (const response of responses) {
          if (response.error) {
            return { error: response.error };
          }
        }

        return {
          data: {
            ...(responses[0].data as SubjectSkillResults),
            ...(responses[1].data as StudentResultOverview),
          },
        };
      },
    }),
    getSkills: builder.query<
      Array<{
        _id: string;
        skill: string;
        subject: string;
        isAvailableForTargetedPractice?: boolean;
      }>,
      { subject: Subject; trackId?: string }
    >({
      query: ({ subject, trackId }) => ({
        url: `/skills/${subject}`,
        params: { trackId },
      }),
    }),
    getTargetedPracticeSkillsInfo: builder.query<
      Array<{
        skill: string;
        numOfProblems: number;
        numOfTargetedPracticeProblems: number;
        numOfOutstandingPractices: number;
        averageResult?: number;
      }>,
      void
    >({
      query: () => ({
        url: `/skills/targeted-practice`,
      }),
    }),
    getTargetedPracticeProblems: builder.query<
      {
        problems: TargetedPracticeProblemDocument[];
        hasMore: boolean;
        totalPages: number;
      },
      { skillId?: string; page: number; pageSize: number }
    >({
      query: ({ skillId, page, pageSize }) => ({
        url: `/problems/targeted-practice`,
        params: { page, pageSize, skillId },
      }),
    }),
    createTargetedPracticeProblem: builder.mutation<
      TargetedPracticeProblemDocument,
      // NOTE this might change in the future
      Omit<
        TargetedPracticeProblemDocument,
        'id' | 'difficulty' | 'subject' | 'additionalImages'
      >
    >({
      query: (problem) => ({
        url: '/problems/targeted-practice',
        method: 'POST',
        body: problem,
      }),
    }),
    toggleSkillTargetedPracticeAvailability: builder.mutation<
      void,
      { skillId: string; enabled: boolean }
    >({
      query: ({ skillId, enabled }) => ({
        url: '/skills/targeted-practice',
        method: 'POST',
        body: { skillId, enabled },
      }),
    }),
    getTutorials: builder.query<
      TutorialsResponse,
      {
        subject: Subject;
        trackId?: string;
        skills?: string[];
        skip?: number;
        limit?: number;
      }
    >({
      query: ({ subject, trackId, skills, skip, limit }) => {
        // NOTE: as RTKQ uses Fetch which uses URLSearchParams which doesn't support arrays, query is manually built
        const skillsParameters =
          Array.isArray(skills) && skills.length > 0
            ? skills.map((s) => `skills[]=${s}&`).join('')
            : '';
        return {
          url: `/tutorials/${subject}?${skillsParameters}skip=${skip}&limit=${limit}&trackId=${trackId}`,
        };
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.subject}-${queryArgs.skills}`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newResponse: TutorialsResponse) => {
        const tutorials = currentCache.tutorials;
        for (const newItem of newResponse.tutorials) {
          if (
            tutorials.some(
              (tutorial) => tutorial.problemNum === newItem.problemNum,
            )
          ) {
            return;
          }
          tutorials.push(newItem);
        }
        currentCache.tutorials = tutorials;
        currentCache.hasMore = newResponse.hasMore;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
    }),
    getTutorialsForProblems: builder.query<
      Tutorial[],
      { problemNums: string[] }
    >({
      query: ({ problemNums }) => ({
        url: `/tutorials`,
        params: { problemNums: JSON.stringify(problemNums) },
      }),
    }),
    getTutorialsForQuizzes: builder.query<
      Tutorial[],
      { quizIds: string[]; thinkerId?: string }
    >({
      query: ({ quizIds, thinkerId }) => ({
        url: `/tutorials`,
        params: { quizIds: JSON.stringify(quizIds), thinkerId },
      }),
    }),
    getResult: builder.query<ResultDocument, { quizId: string }>({
      query: ({ quizId }) => ({
        url: `/results/${quizId}`,
      }),
    }),
    getIncompleteResult: builder.query<ResultDocument, void>({
      query: () => ({
        url: `/results/incomplete`,
      }),
    }),
    getQuizDetails: builder.query<
      Quiz,
      { quizId: string; trackSerialNum?: string }
    >({
      query: ({ quizId, trackSerialNum }) => ({
        url: `/quizschedules/student/${quizId}`,
        params: { trackSerialNum },
      }),
    }),
    getClassesTrackDetails: builder.query<
      ClassDocument[],
      { classes: string[] }
    >({
      async queryFn({ classes }, _queryApi, _extraOptions, fetchWithBQ) {
        const classesResponse = await fetchWithBQ({
          url: '/classes',
          params: { class: classes },
        });
        if (classesResponse.error) {
          return { error: classesResponse.error };
        }

        // TODO: possibly move this logic to API side
        const trackPromises = (classesResponse.data as ClassDocument[]).map(
          (classItem) =>
            fetchWithBQ({
              url: `/tracks/teacher/${classItem.tracks[0].track}`,
              params: { school: classItem.school },
            }),
        );
        const trackResponses = await Promise.all(trackPromises);

        for (
          let index = 0;
          index < (classesResponse.data as ClassDocument[]).length;
          index++
        ) {
          const classItem = (classesResponse.data as ClassDocument[])[index];
          const trackResponse = trackResponses[index];
          if (trackResponse.error) {
            return { error: trackResponse.error };
          }
          classItem.trackDetails = trackResponse.data as TrackDocument;
        }

        return {
          data: classesResponse.data as ClassDocument[],
        };
      },
      providesTags: (result, error, { classes }) => [
        { type: 'Class', classes },
      ],
    }),
    getClassTotalsForTeacher: builder.query<ClassTotal[], { userId: string }>({
      async queryFn({ userId }, _queryApi, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ({
          url: `/stats/teacher/classes/`,
          params: { teacher: userId },
        });
        if (response.error) {
          return { error: response.error };
        }
        return {
          data: response.data as ClassTotal[],
        };
      },
    }),
    getClassResultOverview: builder.query<
      ClassResultOverview,
      { teacherId?: string; classId: string }
    >({
      async queryFn(
        { teacherId, classId },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const response = await fetchWithBQ({
          url: `/stats/teacher/classes/${classId}`,
          params: { teacher: teacherId },
        });
        if (response.error) {
          return { error: response.error };
        }
        return {
          data: {
            ...(response.data as ClassResultOverview),
          },
        };
      },
    }),
    getClassSubjectResults: builder.query<
      ClassSubjectResult,
      { subject?: Subject; classId: string; teacherId: string }
    >({
      async queryFn(
        { subject, classId, teacherId },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const response = await fetchWithBQ({
          url: `/stats/teacher/breakout/${subject}`,
          params: { teacher: teacherId, class: classId },
        });
        if (response.error) {
          return { error: response.error };
        }
        return {
          data: {
            ...(response.data as ClassSubjectResult),
          },
        };
      },
    }),
    getClassStudents: builder.query<UserDocument[], { classId: string }>({
      query: ({ classId }) => ({
        url: `/classes/${classId}/students`,
      }),
    }),
    getClassStudentResults: builder.query<
      ExtendedStudentResult[],
      { classId: string }
    >({
      async queryFn({ classId }, _queryApi, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ({
          url: `/stats/teacher/by-student/`,
          params: { class: classId },
        });
        if (response.error) {
          return { error: response.error };
        }
        return {
          data: response.data as ExtendedStudentResult[],
        };
      },
    }),
    getClassWeakestSkills: builder.query<
      ExtendedSkillResult[],
      { classId: string }
    >({
      async queryFn({ classId }, _queryApi, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ({
          url: `/stats/teacher/classes/${classId}/weakest-skills`,
        });
        if (response.error) {
          return { error: response.error };
        }
        return {
          data: response.data as ExtendedSkillResult[],
        };
      },
    }),
    getClassStudentSkillResults: builder.query<
      TableDataResponse<TargetedPracticeAssignListItem>,
      { classId: string; skills: string[] }
    >({
      query: ({ classId, skills }) => ({
        url: `/stats/teacher/classes/${classId}/skill-results`,
        params: { skills: JSON.stringify(skills) },
      }),
      transformResponse: (
        response: {
          studentId: string;
          studentName: string;
          skillResults: ExtendedSkillResult[];
        }[],
      ): TableDataResponse<TargetedPracticeAssignListItem> => {
        return {
          items: response.map((item) => ({
            studentId: item.studentId,
            studentName: item.studentName,
            skillresults: item.skillResults,
          })),
          total: response.length,
        };
      },
    }),
    getTracks: builder.query<TrackDocument[], void>({
      query: () => ({
        url: `/tracks`,
      }),
    }),
    getTracksForSignup: builder.query<
      { middleSchoolTracks: SignupTrack[]; highSchoolTracks: SignupTrack[] },
      void
    >({
      query: () => ({
        url: `/tracks/signup`,
      }),
    }),
    getTracksForSchool: builder.query<SchoolTrack[], string>({
      query: (school) => ({
        url: `/tracks/by-school/${school}`,
      }),
    }),
    getTracksForStudent: builder.query<
      StudentTrackWithSchedule,
      { studentId: string; shouldFetchWorkbookUrls?: boolean }
    >({
      query: ({ studentId, shouldFetchWorkbookUrls }) => ({
        url: `/tracks/student/${studentId}`,
        params: { shouldFetchWorkbookUrls },
      }),
    }),
    getSchoolResultOverview: builder.query<
      SchoolResultOverview,
      { school: string; trackId?: string }
    >({
      query: ({ school, trackId }) => ({
        url: trackId
          ? `/stats/schooladmin/tracks/overview/${trackId}`
          : `/stats/schooladmin/overview`,
        params: { school },
      }),
    }),
    getSchoolSubjectResults: builder.query<
      ExtendedSubjectResult[],
      { school: string; trackId?: string }
    >({
      query: ({ school, trackId }) => ({
        url: trackId
          ? `/stats/schooladmin/tracks/${trackId}/subjects`
          : `/stats/schooladmin/subjects`,
        params: { school },
      }),
    }),
    getSchoolSubjectSkillResults: builder.query<
      ExtendedSkillResult[],
      { school: string; subject: Subject; trackId?: string }
    >({
      query: ({ school, subject, trackId }) => ({
        url: trackId
          ? `/stats/schooladmin/tracks/${trackId}/subjects/${subject}`
          : `/stats/schooladmin/subjects/${subject}`,
        params: { school },
      }),
    }),
    getSchoolTeacherResults: builder.query<
      TeacherResult[],
      { school: string; trackId?: string }
    >({
      query: ({ school, trackId }) => ({
        url: trackId
          ? `/stats/schooladmin/tracks/teachers/${trackId}`
          : `/stats/schooladmin/teachers`,
        params: { school },
      }),
    }),
    getThinkerResultsForMentor: builder.query<
      (StudentResult & { name: string; quizResults: QuizResult[] })[],
      void
    >({
      query: () => ({
        url: 'stats/mentor/thinkers',
      }),
    }),
    getImportHistory: builder.query<ImportDocument[], void>({
      query: () => ({
        url: `/import/history`,
      }),
      providesTags: ['ImportHistory'],
      async onQueryStarted(arguments_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (
            Array.isArray(data) &&
            data.length > 0 &&
            data[0].status === ImportStatus.processing &&
            dayjs().subtract(20, 'minutes').isBefore(data[0].updatedAt)
          ) {
            dispatch(
              setImportDetails({
                _id: data[0]._id,
                status: ImportStatus.processing,
                progress: 1,
                stats: {} as ImportStats,
                warnings: [],
                importErrors: [],
              }),
            );
            dispatch(apiSlice.endpoints.getImportDetails.initiate(data[0]._id));
          }
        } catch (error) {
          // eslint-disable-next-line unicorn/no-useless-undefined
          dispatch(apiSlice.endpoints.sendErrorContext.initiate(undefined));
          toast.error('Failed to fetch Import history. Please try again.');
          Sentry.captureException(error, {
            tags: {
              request: 'getImportHistory',
            },
          });
        }
      },
    }),
    getImportDetails: builder.query<ImportDocument, string>({
      query: (importProcessId) => ({
        url: `/import/${importProcessId}`,
      }),
      providesTags: ['ImportDetails'],
      async onQueryStarted(arguments_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setImportDetails(data));
          }
        } catch (error) {
          // eslint-disable-next-line unicorn/no-useless-undefined
          dispatch(apiSlice.endpoints.sendErrorContext.initiate(undefined));
          toast.error('Failed to fetch Import details. Please try again.');
          Sentry.captureException(error, {
            tags: {
              request: 'getImportDetails',
            },
          });
        }
      },
    }),
    getSkillsForProblems: builder.query<
      ProblemSkills[],
      {
        problems: string[];
      }
    >({
      query: ({ problems }) => ({
        url: '/problems/skills',
        params: { problems: JSON.stringify(problems) },
      }),
    }),
    startQuizSolving: builder.mutation<
      Partial<Quiz>,
      {
        quizId: string;
        trackId: string;
      }
    >({
      query: ({ quizId, trackId }) => ({
        url: '/results/init',
        method: 'POST',
        body: {
          quiz: {
            id: quizId,
          },
          track: trackId,
        },
      }),
      async onQueryStarted(arguments_, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (apiError: any) {
          handleInitQuizApiError({
            apiError,
            dispatch,
            getState,
          });
        }
      },
    }),
    getSubjectResultsForResultId: builder.query<
      SubjectResult[], // TODO cehck api not to provide franctional
      {
        resultId: string;
      }
    >({
      query: ({ resultId }) => ({
        url: `/results/subjects/${resultId}`,
      }),
    }),
    getSkillResultsForResultId: builder.query<
      SkillResult[],
      {
        resultId: string;
      }
    >({
      query: ({ resultId }) => ({
        url: `/results/skills/${resultId}`,
      }),
    }),
    sendQuizAnswer: builder.mutation<ResultDocument, QuizAnswerPayload>({
      query: (quizSubmission) => ({
        url: `/results/${quizSubmission._id}`,
        method: 'PUT',
        body: quizSubmission,
      }),
      async onQueryStarted(arguments_, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (apiError: any) {
          handleUpdateResultApiError({
            apiError,
            dispatch,
            getState,
            request: 'sendQuizAnswer',
          });
        }
      },
    }),
    finalizeQuizSubmission: builder.mutation<
      ResultDocument,
      { resultId: string; quiz: SubmittedQuiz }
    >({
      query: ({ resultId, quiz }) => ({
        url: `/results/${resultId}`,
        method: 'PUT',
        body: {
          _id: resultId,
          isCompleted: true,
          quiz,
        },
      }),
      async onQueryStarted(arguments_, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const problemNums = data.quiz.problems.map((p) => p.problemId);
          dispatch(
            apiSlice.util.prefetch(
              'getTutorialsForProblems',
              { problemNums },
              {},
            ),
          );
          dispatch(
            apiSlice.util.prefetch(
              'getSubjectResultsForResultId',
              { resultId: data._id },
              {},
            ),
          );
          dispatch(
            apiSlice.util.prefetch(
              'getSkillResultsForResultId',
              { resultId: data._id },
              {},
            ),
          );
        } catch (apiError: any) {
          handleUpdateResultApiError({
            apiError,
            dispatch,
            getState,
            request: 'finalizeQuizSubmission',
          });
        }
      },
    }),
    submitPractice: builder.mutation<
      { quiz: SubmitPracticeResponse; isStudentOrThinker?: boolean },
      {
        trackId: string;
        quiz: SubmittedQuiz;
        answerDuration: number;
      }
    >({
      query: ({ trackId, quiz, answerDuration }) => ({
        url: '/results/practice',
        method: 'POST',
        body: {
          track: trackId,
          quiz,
          answerDuration,
        },
      }),
      async onQueryStarted(arguments_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          // eslint-disable-next-line unicorn/no-useless-undefined
          dispatch(apiSlice.endpoints.sendErrorContext.initiate(undefined));
          toast.error('Failed to submit practice, sorry.');
          Sentry.captureException(error, {
            tags: {
              request: 'submitPractice',
            },
          });
        }
      },
      transformResponse: (
        response: SubmitPracticeResponse,
      ): { quiz: SubmitPracticeResponse; isStudentOrThinker?: boolean } => {
        // return user role state so it's accessible in the matcher
        const { user } = store.getState();
        if (!user.user) {
          return { quiz: response };
        }
        const isStudentOrThinker =
          user.user.roles.includes(ROLES.student) ||
          user.user.roles.includes(ROLES.thinker);
        return {
          quiz: response,
          isStudentOrThinker,
        };
      },
    }),
    startQuizPracticing: builder.query<
      { quiz: Partial<Quiz>; isStudentOrThinker?: boolean },
      {
        quizId: string;
        trackId: string;
      }
    >({
      query: ({ quizId, trackId }) => ({
        url: `/problems`,
        params: {
          quizId,
          trackId,
        },
      }),
      transformResponse: (
        response: Partial<Quiz>,
      ): { quiz: Partial<Quiz>; isStudentOrThinker?: boolean } => {
        // return user role state so it's accessible in the matcher
        const { user } = store.getState();
        if (!user.user) {
          return { quiz: response };
        }
        const isStudentOrThinker =
          user.user.roles.includes(ROLES.student) ||
          user.user.roles.includes(ROLES.thinker);
        return {
          quiz: response,
          isStudentOrThinker,
        };
      },
    }),
    updateReviewProgress: builder.mutation<
      UserDocument,
      {
        problemId: string;
        problemNum: number;
        quizId: string;
        didFinishReview: boolean;
      }
    >({
      query: ({ problemId, problemNum, quizId, didFinishReview }) => ({
        url: '/users/reviews/progress',
        method: 'POST',
        body: {
          problemId,
          problemNum,
          quizId,
          didFinishReview,
        },
      }),
      async onQueryStarted(arguments_, { dispatch, queryFulfilled }) {
        const tags = {
          request: 'updateReviewProgress',
          quizId: arguments_.quizId,
          problemId: arguments_.problemId,
          problemNum: arguments_.problemNum,
          didFinishReview: arguments_.didFinishReview,
        };
        try {
          if (arguments_.didFinishReview) {
            dispatch(setLastQuizReviewFinished(true));
          }
          await queryFulfilled;
          Sentry.addBreadcrumb({
            category: 'Review',
            message: 'Review progress updated',
            data: tags,
          });
        } catch (error) {
          // eslint-disable-next-line unicorn/no-useless-undefined
          dispatch(apiSlice.endpoints.sendErrorContext.initiate(undefined));
          toast.error('Failed to update review progress, sorry.');
          Sentry.captureException(error, {
            tags,
          });
        }
      },
    }),
    getQuizTracks: builder.query<
      ExtendedTrack,
      {
        school?: string;
        trackId?: string;
        classId?: string;
        isTeacher: boolean;
      }
    >({
      query: ({ isTeacher, classId, school, trackId }) => ({
        url: isTeacher ? `/tracks/teacher` : `/tracks/schooladmin`,
        params: isTeacher
          ? {
              class: classId,
            }
          : {
              school,
              track: trackId,
            },
      }),
    }),
    getCheckpointTests: builder.query<
      Quiz[],
      { studentId: string; trackId: string }
    >({
      query: ({ studentId, trackId }) => ({
        url: `/tracks/checkpoint-tests/${trackId}`,
        params: { studentId },
      }),
    }),
    getQuizScores: builder.query<
      QuizStats,
      {
        quizId?: string;
        trackId?: string;
        classId?: string;
        roles: ROLES[];
        school?: string;
        studentId?: string;
      }
    >({
      query: ({ roles, classId, school, trackId, quizId, studentId }) => {
        const url = studentId
          ? '/stats/student/quizscores'
          : roles.includes(ROLES.teacher)
          ? `/stats/teacher/quizscores/${classId}/${quizId}`
          : roles.includes(ROLES.schooladmin)
          ? `/stats/schooladmin/quizscores/${trackId}/${quizId}`
          : roles.includes(ROLES.mentor)
          ? `stats/mentor/quizscores/${trackId}/${quizId}`
          : '';

        return {
          url,
          params: studentId
            ? { studentId }
            : roles.includes(ROLES.schooladmin)
            ? {
                school,
              }
            : {},
          refetchOnMountOrArgChange: true,
        };
      },
    }),
    getStudentQuizStats: builder.query<
      StudentQuizStats,
      { quizId: string; studentId: string }
    >({
      query: ({ quizId, studentId }) => ({
        url: `/stats/student/quizscores/${quizId}`,
        params: { studentId },
        refetchOnMountOrArgChange: true,
      }),
    }),
    getMentorQuizScores: builder.query<
      QuizStats,
      {
        quizId: string;
        trackSerialNum: string;
      }
    >({
      query: ({ trackSerialNum, quizId }) => ({
        url: `/stats/mentor/quizscores/${trackSerialNum}/${quizId}`,
      }),
    }),
    getOwnerProblems: builder.query<
      {
        problems: ProblemDocument[];
        hasMore: boolean;
        totalPages: number;
      },
      {
        trackId?: string;
        quizId?: string | null;
        problemId?: string | null;
        page: number;
        pageSize: number;
      }
    >({
      query: ({ trackId, quizId, problemId, page, pageSize }) => ({
        url: `problems/owner`,
        params: {
          trackId,
          quizId,
          problemId,
          page,
          pageSize,
        },
      }),
    }),
    getOwnerProblemIds: builder.query<
      { id: string }[],
      {
        trackId?: string;
        quizId?: string | null;
      }
    >({
      query: ({ trackId, quizId }) => ({
        url: `problems/owner/problemIds`,
        params: {
          trackId,
          quizId,
        },
      }),
    }),
    getSystemLogs: builder.query<
      string,
      {
        from?: string;
        to?: string;
        searchTerm?: string;
      }
    >({
      query: ({ from, to, searchTerm }) => ({
        url: `stats/owner/system/logs`,
        params: {
          from: from ?? undefined,
          to: to ?? undefined,
          search: searchTerm && searchTerm.length > 1 ? searchTerm : undefined,
        },
        responseHandler: 'text',
      }),
    }),
    getUsersList: builder.query<
      TableDataResponse<UsersListItem>,
      GetUsersListParameters
    >({
      providesTags: ['UsersList'],
      query: ({ skip, limit, sortBy, desc, search }) => ({
        url: `users/owner`,
        params: {
          skip,
          limit,
          sortBy,
          desc,
          search,
        },
      }),
    }),
    deleteUserResult: builder.mutation<
      void,
      {
        userId: string;
        quizId: string;
      }
    >({
      invalidatesTags: ['UsersList'],
      query: ({ userId, quizId }) => ({
        url: `results/${userId}`,
        method: 'DELETE',
        body: { quizId },
      }),
    }),
    pushUserThroughReview: builder.mutation<void, string>({
      invalidatesTags: ['UsersList'],
      query: (userId) => ({
        url: `users/reviews/push-through`,
        method: 'POST',
        body: {
          userId,
        },
      }),
      async onQueryStarted(userId, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(`Failed to push through the review user ${userId}`);
          console.error('error at pushUserThroughReview', error);
        }
      },
    }),
    assignTargetedPractices: builder.mutation<
      void,
      { classId: string; payload: AssignTargetedPracticesPayload }
    >({
      query: ({ classId, payload }) => ({
        url: `/classes/${classId}/targeted-practice`,
        method: 'POST',
        body: payload,
      }),
    }),
    getAssignedTargetedPractices: builder.query<
      TableDataResponse<AssignedTargetedPracticeListItem>,
      { classId: string }
    >({
      query: ({ classId }) => ({
        url: `/classes/${classId}/targeted-practice`,
      }),
      transformResponse: (
        response: {
          studentId: string;
          studentName: string;
          targetedPractices: TargetedPractice[];
        }[],
      ): TableDataResponse<AssignedTargetedPracticeListItem> => {
        return {
          items: response.map((item) => ({
            studentId: item.studentId,
            studentName: item.studentName,
            targetedPractices: item.targetedPractices,
          })),
          total: response.length,
        };
      },
    }),
    getTargetedPracticeResultsByClass: builder.query<
      TargetedPracticeResult[],
      { classId: string }
    >({
      query: ({ classId }) => ({
        url: `/results/targeted-practice/by-class/${classId}`,
      }),
    }),
    toggleFeatureForUser: builder.mutation<
      UserDocument,
      { userId: string; feature: string; enabled: boolean }
    >({
      query: (payload) => ({
        url: `users/features`,
        method: 'POST',
        body: payload,
      }),
    }),
    getThinkersOfMentor: builder.query<UserDocument[], { mentorId: string }>({
      query: ({ mentorId }) => ({
        url: `/users/individual-thinkers`,
        params: { mentorId },
      }),
    }),
    addThinker: builder.mutation<void, AddThinkerFormData>({
      query: (formData) => ({
        url: '/users/individual-thinker',
        method: 'POST',
        body: formData,
      }),
    }),
    getMentors: builder.query<UsersListItem[], void>({
      query: () => ({
        url: '/users/roles',
        params: { roles: [ROLES.mentor] },
      }),
    }),
    getSchools: builder.query<string[], void>({
      query: () => ({
        url: 'classes/schools',
      }),
    }),
    individualThinkerSignup: builder.mutation<
      string, // mentor user ID
      {
        paymentIntentId: string;
        mentor: SignUpState['mentor'];
        thinkers: SignUpState['thinkers'];
      }
    >({
      query: (payload) => ({
        url: '/users/individual-thinker/signup',
        method: 'POST',
        body: payload,
      }),
    }),
    createPaymentIntent: builder.mutation<
      { client_secret: string; paymentIntentId: string },
      { amount: number }
    >({
      query: ({ amount }) => ({
        url: '/payment/payment-intent',
        method: 'POST',
        body: { amount },
      }),
    }),
    updatePaymentIntentAmount: builder.mutation<
      void,
      { paymentIntentId: string; amount: number }
    >({
      query: ({ paymentIntentId, amount }) => ({
        url: `/payment/payment-intent/${paymentIntentId}`,
        method: 'PUT',
        body: { amount },
      }),
    }),
    applyCoupon: builder.mutation<
      { discount_amount: number },
      { paymentIntentId?: string; couponCode: string; amount: number }
    >({
      query: ({ couponCode, amount, paymentIntentId }) => ({
        url: paymentIntentId
          ? `/payment/coupon/${paymentIntentId}`
          : '/payment/coupon',
        method: 'PUT',
        body: { couponCode, amount },
      }),
    }),
    calculateTax: builder.query<
      { amount_total: number; tax_amount_exclusive: number },
      {
        address: Address;
        amount: number;
      }
    >({
      query: ({ address, amount }) => ({
        url: '/payment/tax',
        params: { address: JSON.stringify(address), amount },
      }),
    }),
    checkEmailExists: builder.query<boolean, { email: string }>({
      query: ({ email }) => ({
        url: `/users/email-exists`,
        params: { email },
      }),
    }),
    getMentorPasswordResetLinkByPaymentId: builder.query<
      string,
      {
        mentorUserId: string;
        paymentIntentId: string;
      }
    >({
      query: ({ mentorUserId, paymentIntentId }) => ({
        url: '/users/individual-thinker/mentor-password-reset-link',
        params: { mentorUserId, paymentIntentId },
        responseHandler: 'text',
      }),
    }),
    updateThinkerTask: builder.mutation<
      void,
      { taskId: string; status: string }
    >({
      query: ({ taskId, status }) => ({
        url: `/users/me/tasks/${taskId}`,
        method: 'PUT',
        body: { status },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useChangePasswordMutation,
  useSendPasswordResetLinkMutation,
  useGetUserByTokenQuery,
  useResetPasswordMutation,
  useUpdatePasswordForUserMutation,
  useUpdateEmailForUserMutation,
  useUpdateUserMetricsSettingsMutation,
  useFinalizeQuizSubmissionMutation,
  useGetClientVersionQuery,
  useGetMyUserQuery,
  useGetSkillsQuery,
  useGetTargetedPracticeSkillsInfoQuery,
  useGetTargetedPracticeProblemsQuery,
  useCreateTargetedPracticeProblemMutation,
  useGetStudentOrThinkerSummaryDataQuery,
  useGetStudentOrThinkerGAPSResultsQuery,
  useGetClassesTrackDetailsQuery,
  useGetClassTotalsForTeacherQuery,
  useGetClassResultOverviewQuery,
  useGetClassSubjectResultsQuery,
  useGetClassStudentsQuery,
  useGetClassStudentResultsQuery,
  useGetClassStudentSkillResultsQuery,
  useGetClassWeakestSkillsQuery,
  useGetQuizTracksQuery,
  useGetCheckpointTestsQuery,
  useGetQuizScoresQuery,
  useGetStudentQuizStatsQuery,
  useGetMentorQuizScoresQuery,
  useGetQuizDetailsQuery,
  useGetResultQuery,
  useGetIncompleteResultQuery,
  useGetSkillResultsForResultIdQuery,
  useGetSubjectResultsForResultIdQuery,
  useGetSchoolResultOverviewQuery,
  useGetSchoolSubjectResultsQuery,
  useGetSchoolSubjectSkillResultsQuery,
  useGetSchoolTeacherResultsQuery,
  useGetThinkerResultsForMentorQuery,
  useGetImportHistoryQuery,
  useLazyGetImportHistoryQuery,
  useGetImportDetailsQuery,
  useGetSkillsForProblemsQuery,
  useGetTracksQuery,
  useGetTracksForSignupQuery,
  useGetTracksForSchoolQuery,
  useGetTracksForStudentQuery,
  useGetTutorialsForProblemsQuery,
  useGetTutorialsForQuizzesQuery,
  useGetTutorialsQuery,
  useStartQuizPracticingQuery,
  useStartQuizSolvingMutation,
  useSendQuizAnswerMutation,
  useSubmitPracticeMutation,
  useUpdateReviewProgressMutation,
  useGetOwnerProblemsQuery,
  useGetOwnerProblemIdsQuery,
  useGetSystemLogsQuery,
  useDeleteUserResultMutation,
  useGetUsersListQuery,
  usePushUserThroughReviewMutation,
  useSwitchUserMutation,
  useAssignTargetedPracticesMutation,
  useGetAssignedTargetedPracticesQuery,
  useGetTargetedPracticeResultsByClassQuery,
  useToggleFeatureForUserMutation,
  useToggleSkillTargetedPracticeAvailabilityMutation,
  useGetThinkersOfMentorQuery,
  useAddThinkerMutation,
  useIndividualThinkerSignupMutation,
  useGetMentorPasswordResetLinkByPaymentIdQuery,
  useGetMentorsQuery,
  useGetSchoolsQuery,
  useCreatePaymentIntentMutation,
  useUpdatePaymentIntentAmountMutation,
  useApplyCouponMutation,
  useLazyCalculateTaxQuery,
  useLazyCheckEmailExistsQuery,
  useUpdateThinkerTaskMutation,
} = apiSlice;

export const selectAnyRequestPending = (state: RootState) =>
  Object.values(state.api.queries).some(
    (query: any) => query.status === 'pending',
  ) ||
  Object.values(state.api.mutations).some(
    (mutation: any) => mutation.status === 'pending',
  );

export const selectAnyReviewRequestPending = (state: RootState) =>
  state.api.queries.getResult?.status === 'pending' ||
  state.api.queries.getQuizDetails?.status === 'pending' ||
  state.api.queries.getTutorialsForProblems?.status === 'pending' ||
  state.api.queries.getSubjectResultsForResultId?.status === 'pending' ||
  state.api.queries.getSkillResultsForResultId?.status === 'pending' ||
  state.api.queries.getSkillsForProblems?.status === 'pending';

const handleInitQuizApiError = ({
  apiError,
  dispatch,
  getState,
}: {
  apiError: any;
  dispatch: any;
  getState: any;
}) => {
  const { endpoints } = apiSlice;
  const { sendErrorContext } = endpoints;
  // eslint-disable-next-line unicorn/no-useless-undefined
  dispatch(sendErrorContext.initiate(undefined));
  let toastText = 'Failed to initialize quiz.';
  if (apiError?.error?.status === 400) {
    const quizState = (getState() as RootState).quiz;
    if (/finalized/gi.test(apiError?.error?.data?.message)) {
      toastText = 'Quiz has already been finalized.';
      dispatch(
        push(
          `/review/${quizState.selectedQuiz?.trackSerialNum}/${quizState.selectedQuiz?.id}`,
        ),
      );
    }
  }
  toast.error(toastText);
  Sentry.captureException(apiError, {
    tags: { request: 'initQuiz' },
  });
};

const handleUpdateResultApiError = ({
  apiError,
  dispatch,
  getState,
  request,
}: {
  apiError: any;
  dispatch: any;
  getState: any;
  request: 'sendQuizAnswer' | 'finalizeQuizSubmission';
}) => {
  const { endpoints } = apiSlice;
  const { sendErrorContext } = endpoints;
  // eslint-disable-next-line unicorn/no-useless-undefined
  dispatch(sendErrorContext.initiate(undefined));
  let toastText =
    'Failed to submit result. Your answers and the context have been recorded.';
  if (apiError?.error?.status === 403) {
    const quizState = (getState() as RootState).quiz;
    if (/overdue/gi.test(apiError?.error?.data?.message)) {
      toastText =
        'Quiz is overdue. Your answers and the context have been recorded. Your result has been finalized.';
      dispatch(
        push(
          `/review/${quizState.selectedQuiz?.trackSerialNum}/${quizState.selectedQuiz?.id}`,
        ),
      );
    }
    if (/completed/gi.test(apiError?.error?.data?.message)) {
      toastText =
        'Quiz is already completed. Your answers and the context have been recorded. Redirecting to review.';
      dispatch(
        push(
          `/review/${quizState.selectedQuiz?.trackSerialNum}/${quizState.selectedQuiz?.id}`,
        ),
      );
    }
  }
  toast.error(toastText);
  Sentry.captureException(apiError, {
    tags: { request },
  });
};
