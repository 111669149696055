import { XIcon } from 'lucide-react';
import {
  PiArrowsCounterClockwiseLight,
  PiCalendarCheckLight,
  PiCheckCircleLight,
  PiClockLight,
  PiSpinnerLight,
  PiStarLight,
} from 'react-icons/pi';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';

import { formatSeconds } from '../../util';
import { StudentQuizStats, UsersListItem } from '../../types';
import { ROLES } from '../../constants';

import {
  useDeleteUserResultMutation,
  useGetTutorialsForQuizzesQuery,
} from '../../services/apiSlice';
import { useAppSelector } from '../../stores/AppStore';
import { selectHasOneOfTheRoles } from '../../features/user/userSlice';

import ScoreBar from '../ScoreBar';
import LoadingSnake from '../LoadingSnake';
import QuizSkillResults from './QuizSkillResults';
import ProblemResultDetails from './ProblemResultDetails';
import Button from '../Button';
import toast from 'react-hot-toast';
import { TrashIcon } from '@heroicons/react/outline';

interface QuizResultDetailsModalProperties {
  quizStats?: StudentQuizStats;
  isLoadingQuizStats?: boolean;
  showReviewStatus?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const QuizResultDetailsModal = ({
  quizStats,
  isLoadingQuizStats,
  showReviewStatus = false,
  isOpen,
  onClose,
}: QuizResultDetailsModalProperties) => {
  const modalReference = useRef<HTMLDivElement>(null);
  const isStudentOrThinker = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student, ROLES.thinker]),
  );
  const isMentor = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.mentor]),
  );
  const user = useAppSelector((state) => state.user.user);

  const { data: tutorialsData, isLoading: isLoaidngTutorials } =
    useGetTutorialsForQuizzesQuery(
      {
        quizIds: [quizStats?.result?.quiz?.id ?? ''],
        thinkerId: quizStats?.result?.student,
      },
      {
        skip:
          (!isStudentOrThinker && !isMentor) ||
          (isMentor && !quizStats?.result?.student) ||
          !quizStats?.result?.quiz?.id,
      },
    );
  const [deleteUserResult /*{ isLoading: isDeletingResult }*/] =
    useDeleteUserResultMutation();

  const handleRemoveResult = async (student?: UsersListItem) => {
    if (!student || !student.lastSubmittedAvailableQuiz) {
      // TODO: error handling
      return;
    }
    if (!user) {
      // TODO: error handling
      // return;
    }
    const confirmed = window.confirm(
      'To confirm, do you want to reset this quiz? This will erase the current quiz score from the Mentor and Thinker dashboard.',
    );

    if (confirmed) {
      try {
        await deleteUserResult({
          userId: student._id,
          quizId: student.lastSubmittedAvailableQuiz,
        }).unwrap();
      } catch {
        toast.error(
          `Some unexpected error happened while trying to reset the Quiz. Please try again later!`,
        );
        return;
      }
      toast.success(`The Quiz was reseted successfully.`);
    }
  };

  const getTutorialForProblem = (problemId: string) => {
    if (!tutorialsData) {
      return;
    }
    return tutorialsData.find((t) => t.problemNum === problemId);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalReference.current &&
        !modalReference.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return <></>;
  }

  if (isLoadingQuizStats || isLoaidngTutorials) {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex z-50">
        <div className="relative flex items-center justify-center bg-white w-full md:w-[80%] xl:w-[60%]">
          <XIcon
            className="absolute right-4 top-4 h-7 w-7 p-1 text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
            onClick={onClose}
          />
          <LoadingSnake />
        </div>
      </div>
    );
  }

  if (!quizStats || !quizStats.result) {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex z-50">
        <div className="relative flex items-center justify-center bg-white w-full md:w-[80%] xl:w-[60%]">
          <XIcon
            className="absolute right-4 top-4 h-7 w-7 p-1 text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
            onClick={onClose}
          />
          An unexpected error happened, please try again.
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid="quiz-result-details"
      className="fixed inset-0 bg-gray-800 bg-opacity-75 flex z-50"
    >
      <div
        ref={modalReference}
        className="flex flex-col gap-4 bg-white w-full md:w-[80%] xl:w-[60%] p-4 md:p-6 overflow-y-auto"
      >
        <div className="flex justify-between items-center">
          <h2 className="text-xl md:text-2xl font-medium">
            {quizStats.result.quiz.id}
          </h2>
          <XIcon
            className="h-7 w-7 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
            onClick={onClose}
          />
        </div>
        {showReviewStatus && (
          <div className="flex items-center gap-3">
            <p className="text-gray-500">Solution Review</p>
            <div
              className={`flex gap-1 items-center rounded-full ${
                quizStats.didFinishReview
                  ? 'bg-green text-green'
                  : 'bg-orange text-orange'
              } bg-opacity-30 text-xs md:text-sm py-1 px-2 h-max whitespace-nowrap`}
            >
              {quizStats.didFinishReview ? (
                <PiCheckCircleLight className="w-5 h-5 text-green" />
              ) : (
                <PiSpinnerLight className="w-5 h-5 text-orange" />
              )}
              {quizStats.didFinishReview ? 'Completed' : 'Pending'}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-2 md:gap-3 text-gray-500 text-sm md:text-base">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="flex items-center">
              <PiCalendarCheckLight className="w-5 h-5 text-tttDefault mr-2" />
              Completed on&nbsp;
              <p className="text-gray-700">
                {dayjs(quizStats.result.completedAt).format('MMM DD, YYYY')}
              </p>
            </div>
            <div className="flex items-center">
              <PiClockLight className="w-5 h-5 text-tttDefault mr-2" />
              Completion Time&nbsp;
              <p className="text-gray-700">
                {formatSeconds(quizStats.result.answerDuration)}
              </p>
            </div>
            {quizStats.numOfPractices !== undefined && (
              <div className="flex items-center">
                <PiArrowsCounterClockwiseLight className="w-5 h-5 text-tttDefault mr-2" />
                Practice Attempts&nbsp;
                <p className="text-gray-700">{quizStats.numOfPractices}</p>
              </div>
            )}
            {quizStats.lastPracticeScore !== undefined && (
              <div className="flex items-center">
                <PiStarLight className="w-5 h-5 text-tttDefault mr-2" />
                Last Practice Score&nbsp;
                <span className="text-gray-700">
                  {quizStats.lastPracticeScore * 100} %
                </span>
              </div>
            )}
            <div className="hidden">
              {/* FIXME: only last result */}
              <Button
                action={() => handleRemoveResult()}
                bgColor={'bg-rose-500'}
                bgColorHover={'bg-red'}
                textColor={'text-white'}
                // disabled={!user.lastSubmittedAvailableQuiz || isDeletingResult}
                // disabledProgress={
                //   user.lastSubmittedAvailableQuiz
                //     ? undefined
                //     : 'No current result to delete'
                // }
              >
                <span>
                  <TrashIcon className="h-4 bg-rose-800 w-4 mr-2 mt-[-4px] inline" />
                  Reset Quiz
                </span>
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-lg md:text-xl font-medium">Original Score</p>
            <ScoreBar result={quizStats.result.quizResult} />
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-lg md:text-xl font-medium">
              Detailed Results By Problem
            </div>
            <div className="flex flex-col gap-1">
              {quizStats.problems?.map((p, index) => (
                <ProblemResultDetails
                  key={index}
                  index={index}
                  problem={p}
                  tutorial={getTutorialForProblem(p.problemId)}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col md:gap-2">
            <p className="text-lg md:text-xl font-medium">Acquired Skills</p>
            <QuizSkillResults skillResults={quizStats.skillResults} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizResultDetailsModal;
