import { useState } from 'react';
import { Address } from '@stripe/stripe-js';

import {
  selectAmounts,
  selectNumberOfLicenses,
  setAmounts,
  setNumberOfLicenses,
} from '../../../features/signUp/signUpSlice';
import {
  useApplyCouponMutation,
  useLazyCalculateTaxQuery,
} from '../../../services/apiSlice';
import { store, useAppSelector } from '../../../stores/AppStore';

import { Skeleton } from '../../ui/skeleton';
import { handleError } from '../../../services/ErrorService';
import { getPrice, getPricePerLicense } from '../../../lib/utils';
import toast from 'react-hot-toast';

interface OrderDetailsProperties {
  // paymentIntentId: string;
  address?: Address;
  isApplyCouponBlocked: boolean;
  setIsLoading: (value: boolean) => void;
}

const OrderDetails = ({
  address,
  isApplyCouponBlocked,
  // paymentIntentId,
  setIsLoading,
}: OrderDetailsProperties) => {
  const [showContactUs, setShowContactUs] = useState(false);
  const numberOfLicenses = useAppSelector(selectNumberOfLicenses);
  const amounts = useAppSelector(selectAmounts);
  const [couponCode, setCouponCode] = useState('');
  const [applyCoupon, applyCouponResponse] = useApplyCouponMutation();
  const [calculateTax, { isFetching }] = useLazyCalculateTaxQuery();

  const handleApplyCoupon = async () => {
    if (amounts?.amount === undefined) {
      return;
    }
    setIsLoading(true);
    const updateResponse = await applyCoupon({
      amount: amounts.amount,
      couponCode,
    });
    // console.log('did update coupon', { updateResponse });
    if ('error' in updateResponse) {
      if (
        !('status' in updateResponse.error) ||
        updateResponse.error.status !== 404
      ) {
        handleError(updateResponse.error);
      }
      toast.error('Applying coupon code failed. Please try again.');
      setIsLoading(false);
      return;
    }

    // console.log('calcate tax', {
    //   discountAmount: updateResponse.data.discount_amount,
    // });

    if (address && updateResponse.data.discount_amount !== undefined) {
      const result = await calculateTax({
        address,
        amount: amounts.amount - updateResponse.data.discount_amount,
      });
      if (!result.data || result.error) {
        store.dispatch(
          setAmounts({
            ...amounts,
            discount: updateResponse.data.discount_amount,
            tax: undefined,
            total: amounts.amount - updateResponse.data.discount_amount,
          }),
        );
        toast.error('Failed to calculate tax. Please try again in a minute.');
        return;
      }
      store.dispatch(
        setAmounts({
          ...amounts,
          discount: updateResponse.data.discount_amount,
          tax: result.data.tax_amount_exclusive,
          total: result.data.amount_total,
        }),
      );
    } else {
      store.dispatch(
        setAmounts({
          ...amounts,
          discount: updateResponse.data.discount_amount,
        }),
      );
    }

    setIsLoading(false);
  };

  // if (!showOrderDetails && window.innerWidth < 768) {
  //   return (
  //     <>
  //       <div className="flex justify-end md:hidden">
  //         <p
  //           className="font-medium underline cursor-pointer hover:text-tttDefault"
  //           onClick={handleShowOrderDetails}
  //           data-testid="order-details-toggle"
  //         >
  //           Order Details
  //         </p>
  //       </div>
  //       <div
  //         data-testId="order-total-summary"
  //         className={`${
  //           showOrderDetails ? 'hidden' : 'flex'
  //         } flex-col gap-1 text-gray-800 bg-gray-100 rounded-xl p-4`}
  //       >
  //         <div className="flex flex-row justify-between">
  //           <p className="text-base md:text-lg font-medium text-gray-800">
  //             {amounts?.total === undefined
  //               ? amounts?.amount
  //                 ? 'Subtotal'
  //                 : 'Amount'
  //               : 'Total'}
  //           </p>
  //           <div
  //             className={`flex font-medium text-right justify-end items-center ${
  //               Number.isFinite(amounts?.total)
  //                 ? 'text-base md:text-lg text-gray-800'
  //                 : 'text-gray-300'
  //             }`}
  //           >
  //             {isFetching ? (
  //               <Skeleton className="w-8 h-[90%] rounded-full bg-tttDefault bg-opacity-50" />
  //             ) : amounts?.total === undefined ? (
  //               amounts?.amount ? (
  //                 `$${(amounts?.amount / 100).toFixed(2)}`
  //               ) : (
  //                 '$XX.xx'
  //               )
  //             ) : (
  //               `$${(amounts.total / 100).toFixed(2)}`
  //             )}
  //           </div>
  //         </div>
  //         <p
  //           className="text-sm text-tttDefault hover:underline cursor-pointer self-end"
  //           data-testId="enter-coupon-button"
  //           onClick={handleShowOrderDetails}
  //         >
  //           Enter Coupon Code
  //         </p>
  //       </div>
  //     </>
  //   );
  // }

  return (
    <div>
      <h1 className="text-2xl md:text-3xl font-medium w-full pb-2 md:pb-4">
        Step 1: Enroll Now
      </h1>
      <div
        data-testId="full-order-details"
        className="flex flex-col gap-4 md:w-[90%] md:bg-gray-100 text-gray-800 rounded-xl md:p-8 animate-slideInFromRight animate-slideOutToLeft"
      >
        <div className="text-normal text-gray-500">
          One subscription per student. Each student can follow one learning
          track. You can choose from 5th to 12th grade or ACT/SAT Math prep.
        </div>
        {/* <div className="flex justify-between items-center md:hidden">
          <h1 className="text-2xl font-medium w-full pb-2">Order Details</h1>
          <img
            src="/images/x-icon.svg"
            alt="x-icon"
            className="w-8 h-8 cursor-pointer"
            onClick={handleShowOrderDetails}
          />
        </div> */}
        <div data-testid="number-of-licenses" className="flex gap-2">
          <img
            src="/images/icons/licence.svg"
            alt="licence-icon"
            className="text-tttDefault w-5 h-5 pt-1"
          />
          <div>
            <p className="text-gray-500">
              Number of Students — <strong>Annual (12 mo.)</strong> subscription
              prices
            </p>
          </div>
        </div>

        <div className="max-w-full">
          {Array.from({ length: 4 }, (_, index) => (
            <div className="inline-block h-24 mb-2 xl:mx-2">
              <div
                className={`
                      relative flex w-[72px] md:w-20 h-full rounded-xl border border-gray-300 m-1 px-auto py-2 aspect-square cursor-pointer
                      hover:shadow-md hover:outline hover:outline-tttDefault hover:outline-[1px] hover:bg-tttDefault hover:bg-opacity-5
                      ${
                        numberOfLicenses === index + 1
                          ? 'shadow-md outline outline-tttDefault outline-[1px] bg-tttDefault bg-opacity-5'
                          : ''
                      }
                    `}
                onClick={() => {
                  if (index === 3) {
                    store.dispatch(setNumberOfLicenses(index + 1));
                    store.dispatch(
                      setAmounts({
                        ...amounts,
                        amount: undefined,
                        total: undefined,
                      }),
                    );
                    setShowContactUs(true);
                    return;
                  }
                  setShowContactUs(false);
                  store.dispatch(setNumberOfLicenses(index + 1));
                  store.dispatch(
                    setAmounts({
                      ...amounts,
                      amount: 100 * getPrice(index + 1),
                    }),
                  );
                }}
              >
                <input
                  type="hidden"
                  id={`license-${index + 1}`}
                  data-testid={`license-${index + 1}`}
                  value={index + 1}
                  className="w-5 h-5 place-self-start absolute cursor-pointer"
                />
                <label
                  htmlFor={`license-${index + 1}`}
                  className="flex flex-col gap-2 text-center text-l md:text-xl mx-auto mt-0 mb-auto cursor-pointer"
                >
                  {index === 3 ? '4+' : index + 1}
                  <p className="rounded-full bg-blue-100 text-tttDefault text-sm mx-2 py-0.5 px-3 cursor-pointer">
                    <strong>${getPricePerLicense(index + 1)}</strong>{' '}
                    {index > 0 ? 'each' : ''}
                  </p>
                </label>
              </div>
            </div>
          ))}
        </div>
        {showContactUs && (
          <p className="text-normal text-gray-500 py-2">
            Contact us at{' '}
            <a
              href="mailto:help@thinktankprep.com?subject=More Licenses"
              className="text-tttDefault hover:underline"
            >
              help@thinktankprep.com
            </a>{' '}
            to set up your subscriptions.
          </p>
        )}
        {!showContactUs && (
          <>
            <p className="text-xs text-gray-500">
              Track selection takes place after purchase
            </p>
            <div className="flex flex-col gap-2 mt-auto">
              <label htmlFor="couponCode" className="font-medium">
                Discount Code
              </label>
              <div className="flex flex-row gap-2">
                <input
                  type="text"
                  id="couponCode"
                  className="border border-gray-300 rounded-md px-3 py-1.5 w-full"
                  placeholder="Enter your discount code"
                  onChange={(event) => setCouponCode(event.target.value)}
                />
                <button
                  data-testid={`apply-coupon-button`}
                  onClick={handleApplyCoupon}
                  disabled={
                    isApplyCouponBlocked ||
                    isFetching ||
                    applyCouponResponse.isLoading
                  }
                  className="
            px-6 py-1.5 rounded-full border border-tttDefault text-tttDefault font-medium
            transition-all duration-300 ease-in-out
            hover:shadow hover:outline hover:outline-tttDefault hover:outline-[1px] hover:bg-tttDefault hover:bg-opacity-5
            disabled:opacity-50 disabled:cursor-not-allowed
          "
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-1 w-full self-end text-sm text-gray-500">
              <p>Subtotal</p>
              <p
                className={`text-right ${
                  Number.isFinite(amounts?.amount)
                    ? 'text-gray-800'
                    : 'text-gray-300'
                }`}
              >
                {amounts?.amount === undefined
                  ? '$XXX.xx'
                  : `$${(amounts.amount / 100).toFixed(2)}`}
              </p>
              {amounts?.discount !== undefined && (
                <>
                  <p>Discount</p>
                  <div
                    data-testid={'discount-amount'}
                    className={`flex text-right justify-end items-center ${
                      Number.isFinite(amounts?.amount)
                        ? 'text-gray-800'
                        : 'text-gray-300'
                    }`}
                  >
                    {applyCouponResponse.isLoading ? (
                      <Skeleton className="w-8 h-[90%] rounded-full bg-tttDefault bg-opacity-50" />
                    ) : (
                      ` -$${(amounts.discount / 100).toFixed(2)}`
                    )}
                  </div>
                </>
              )}
              <p>Tax</p>
              <div
                data-testid={`tax-amount`}
                className={`flex text-right text-sm justify-end items-center ${
                  Number.isFinite(amounts?.tax)
                    ? 'text-gray-800'
                    : 'text-gray-300'
                }`}
              >
                {isFetching ? (
                  <Skeleton className="w-8 h-[90%] rounded-full bg-tttDefault bg-opacity-50" />
                ) : amounts?.tax === undefined ? (
                  '$XX.xx'
                ) : (
                  `$${(amounts.tax / 100).toFixed(2)}`
                )}
              </div>
              <p className="text-base md:text-lg font-medium text-gray-800">
                Total
              </p>
              <div
                className={`flex font-medium text-right justify-end items-center ${
                  Number.isFinite(amounts?.total)
                    ? 'text-base md:text-lg text-gray-800'
                    : 'text-gray-300'
                }`}
              >
                {isFetching ? (
                  <Skeleton className="w-8 h-[90%] rounded-full bg-tttDefault bg-opacity-50" />
                ) : amounts?.total === undefined ? (
                  '$XXX.xx'
                ) : (
                  `$${(amounts.total / 100).toFixed(2)}`
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
