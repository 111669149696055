import { ChevronUp } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import dayjs from 'dayjs';

import { history, store, useAppSelector } from './stores/AppStore';
import {
  getDefaultPagePath,
  selectHasOneOfTheRoles,
  selectIsAuthenticated,
} from './features/user/userSlice';
import {
  apiSlice,
  useGetClientVersionQuery,
  useGetMyUserQuery,
  useGetTracksForStudentQuery,
} from './services/apiSlice';
import useIncompleteQuizRedirect from './hooks/useIncompleteQuizRedirect';
import useScrollToTop from './hooks/useScrollToTop';
import { getGAtrackingEnabled, initGA } from './util';
import {
  FeatureFlag,
  ROLES,
  isFeatureFlagOverridden,
  quizscheduleTimeZone,
} from './constants';
import { useStores } from './stores/RootStore';
import AssignmentsPage from './pages/Assignments';
import StudentSummary from './pages/StudentSummary';
import Login from './pages/Login';
import StudentGapsBreakout from './pages/StudentGapsBreakout';
import Tutorials from './pages/Tutorial';
import { Footer } from './components/Footer';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { ToastNotification } from './components/Toast';
import Quiz from './pages/Quiz';
import Auth from './components/Auth';
import Review from './pages/Review';
import StudentScores from './pages/StudentScores';
import ClassGapsScores from './pages/ClassGapsScores';
import TeacherScores from './pages/TeacherScores';
import SchoolGapsScores from './pages/SchoolGapsScores';
import TeacherQuiz from './pages/SchooladminTeacherAssignments';
import PasswordReset from './pages/PasswordReset';
import PasswordRestore from './pages/PasswordRestore';
import PasswordChange from './pages/PasswordChange';
import DataImport from './pages/DataImport';
import Help from './pages/Help';
import UsersPage from './pages/UsersPage';
import KPIDashboard from './pages/KPIDashboard';
import Error from './pages/Error';
import { Maintenance } from './pages/Maintenance';
import ThinkerScores from './pages/ThinkerScores';
import MentorAssignments from './pages/MentorAssignments';
import ProblemPreview from './pages/ProblemPreview';
import SystemLogs from './pages/SystemLogs';
import ClassTargetedPractice from './pages/TargetedPractice/ClassTargetedPractice';
import TargetedPracticeManager from './pages/TargetedPractice/TargetedPracticeManager';
import TargetedPracticeProblems from './pages/TargetedPractice/TargetedPracticeProblems';
import SignUp from './pages/SignUp';
import SignupHeader from './components/SignUp/Header';
import { TermsConditions } from './pages/TermsAndConditions';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import About from './pages/About';

const App: FC = () => {
  const {
    rootStore: { uiStore, statsStore },
  } = useStores();
  const navigate = useNavigate();
  const isSignUp = /sign-up/.test(history.location.pathname);
  const isLogin = /login/.test(history.location.pathname);
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const hideMenus = uiStore.hideMenus.bind(uiStore);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const defaultPagePath = useAppSelector(getDefaultPagePath);
  const { user, token } = useAppSelector((state) => state.user);
  const isStudent = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student]),
  );
  const isThinker = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.thinker]),
  );
  useGetMyUserQuery(undefined, {
    skip: !token,
    refetchOnMountOrArgChange: true, // ensures initial fetching (i.e. on reload)
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const { data: track } = useGetTracksForStudentQuery(
    { studentId: user?._id ?? '' },
    {
      skip: (!isStudent && !isThinker) || !user?._id,
    },
  );
  useGetClientVersionQuery(); // to ensure that refetching happens when client version is invalidated
  useIncompleteQuizRedirect();
  const lastSubmittedQuiz =
    user?.reviewedQuizzes &&
    user?.reviewedQuizzes[user?.reviewedQuizzes.length - 1];
  const scrollButtonVisible = useScrollToTop();

  const handleError = (error: Error) => {
    Sentry.captureException(error);
    statsStore.sendErrorStats();
    if (!window.location.pathname.includes(defaultPagePath)) {
      // avoid infinite loop
      setTimeout(() => {
        navigate(defaultPagePath);
        window.location.reload();
      }, 5000);
    }
  };

  useEffect(() => {
    // updates user object on path change
    if (token && !/login/.test(history.location.pathname)) {
      store.dispatch(apiSlice.util.invalidateTags(['User']));
    }
  }, [history.location.pathname, token]);

  useEffect(() => {
    const enableTracking = async () => {
      const GAtrackigEnabled = await getGAtrackingEnabled();
      if (GAtrackigEnabled) {
        setShowCookieConsent(true);
      }
      const isConsent = getCookieConsentValue();
      if (isConsent === 'true') {
        initGA();
      }
    };
    enableTracking();
  }, []);

  useEffect(() => {
    // Send page view on path change
    const isConsent = getCookieConsentValue();
    if (isConsent === 'true') {
      ReactGA.send({ hitType: 'pageview', page: history.location.pathname });
    }

    // Redirect to unfinished review
    const isLastSubmittedQuizInCurrentAssignments =
      !!track &&
      track.currentAssignments.findIndex(
        (a) => a.id === lastSubmittedQuiz?.id,
      ) > -1;
    const lastSubmittedQuizSchedule = track?.currentSchedules.find(
      (s) => s.quiz === lastSubmittedQuiz?.id,
    );
    const isReviewCurrent = isStudent
      ? !!lastSubmittedQuizSchedule &&
        dayjs().isBefore(
          dayjs(lastSubmittedQuizSchedule.dueDate)
            .utc(false)
            .tz(quizscheduleTimeZone, true),
        )
      : true; // Thinkers have to complete any review
    if (
      !!user &&
      !!track &&
      user.reviewedQuizzes &&
      user.reviewedQuizzes?.length > 0 &&
      lastSubmittedQuiz?.didFinishReview === false &&
      isLastSubmittedQuizInCurrentAssignments &&
      isReviewCurrent &&
      !/quiz/.test(history.location.pathname) &&
      !/review/.test(history.location.pathname) &&
      !/help/.test(history.location.pathname) &&
      !/change-password/.test(history.location.pathname)
    ) {
      Sentry.addBreadcrumb({
        message: `Redirect to the mandatory solution review of ${lastSubmittedQuiz.id}`,
      });
      navigate(`/review/${track.serialNum}/${lastSubmittedQuiz.id}`);
    }
  }, [history.location.pathname, user, track]);

  return (
    <ErrorBoundary FallbackComponent={Error} onError={handleError}>
      <div
        onClick={hideMenus}
        className={`flex flex-col min-h-full gap-6 md:gap-8 lg:gap-12 ${
          isAuthenticated &&
          [
            ROLES.owner,
            ROLES.schooladmin,
            ROLES.teacher,
            ROLES.mentor,
          ].includes(user?.roles[0] as ROLES)
            ? ''
            : 'select-none cursor-default'
        }`}
      >
        {isSignUp ? (
          <div className="w-full max-w-[1400px] px-3 md:px-6 mb-4 mx-auto">
            <SignupHeader />
          </div>
        ) : isLogin ? (
          <></>
        ) : (
          <Header />
        )}

        <div
          className={`flex flex-1 lg:gap-2 mx-auto w-full max-w-[1400px] ${
            !isAuthenticated && 'place-content-center'
          }`}
        >
          {isAuthenticated && <Sidebar />}
          <div className={`${isAuthenticated && `flex-1`} md:px-2 w-full`}>
            {uiStore.apiError ? (
              <Error />
            ) : uiStore.apiNetworkError ? (
              <Maintenance />
            ) : (
              <Routes>
                <Route
                  element={
                    <Auth allowedRoles={[ROLES.student, ROLES.thinker]} />
                  }
                >
                  <Route path="/summary" element={<StudentSummary />} />
                  <Route
                    path="/gaps-breakout"
                    element={<StudentGapsBreakout />}
                  />
                </Route>

                <Route
                  element={
                    <Auth allowedRoles={[ROLES.student, ROLES.thinker]} />
                  }
                >
                  <Route
                    path="/assignments/scores/:quizId"
                    element={<AssignmentsPage />}
                  />
                  <Route path="/assignments" element={<AssignmentsPage />} />
                </Route>

                <Route element={<Auth allowedRoles={[ROLES.teacher]} />}>
                  <Route
                    path="/teacher/student-scores/:classId"
                    element={<StudentScores />}
                  />
                  <Route
                    path="/teacher/student-scores"
                    element={<StudentScores />}
                  />
                  <Route
                    path="/teacher/gaps-scores/:classId"
                    element={<ClassGapsScores />}
                  />
                  <Route
                    path="/teacher/gaps-scores"
                    element={<ClassGapsScores />}
                  />
                  <Route
                    path="/teacher/assignments"
                    element={<TeacherQuiz />}
                  />
                  {/* TODO: remove this check after targeted practice is fully implemented */}
                  {(isFeatureFlagOverridden(FeatureFlag.TargetedPractice) ||
                    user?.featureFlags?.includes(
                      FeatureFlag.TargetedPractice,
                    )) && (
                    <Route
                      path="/targeted-practice/assign"
                      element={<ClassTargetedPractice />}
                    />
                  )}
                </Route>

                <Route element={<Auth allowedRoles={[ROLES.schooladmin]} />}>
                  <Route
                    path="/schooladmin/teacher-scores"
                    element={<TeacherScores />}
                  />
                  <Route
                    path="/schooladmin/teacher-scores/:trackId"
                    element={<TeacherScores />}
                  />
                  <Route
                    path="/schooladmin/gaps-scores"
                    element={<SchoolGapsScores />}
                  />
                  <Route
                    path="/schooladmin/gaps-scores/:trackId"
                    element={<SchoolGapsScores />}
                  />
                  <Route
                    path="/schooladmin/assignments"
                    element={<TeacherQuiz />}
                  />
                </Route>

                <Route element={<Auth allowedRoles={[ROLES.mentor]} />}>
                  <Route path="/mentor/thinkers" element={<ThinkerScores />} />
                  <Route
                    path="/mentor/assignments"
                    element={<MentorAssignments />}
                  />
                </Route>

                <Route
                  element={
                    <Auth
                      allowedRoles={[
                        ROLES.owner,
                        ROLES.sysadmin,
                        ROLES.schooladmin,
                        ROLES.teacher,
                        ROLES.student,
                        ROLES.mentor,
                        ROLES.thinker,
                      ]}
                    />
                  }
                >
                  <Route path="/change-password" element={<PasswordChange />} />
                  <Route
                    path="/quiz/:trackId/:quizId/:practiceOrSolve"
                    element={<Quiz />}
                  />
                  <Route
                    path="/quiz/:trackId/:quizId/:practiceOrSolve/:problemId"
                    element={<Quiz />}
                  />
                  <Route path="/review/:trackId/:quizId" element={<Review />} />
                  <Route
                    path="/review/:trackId/:quizId/:problemId"
                    element={<Review />}
                  />
                  <Route path="/tutorials" element={<Tutorials />} />
                  <Route path="/tutorials/:subject" element={<Tutorials />} />
                  <Route path="/help" element={<Help />} />
                </Route>
                <Route element={<Auth allowedRoles={[ROLES.owner]} />}>
                  <Route path="/problem-content" element={<ProblemPreview />} />
                  <Route path="/system-logs" element={<SystemLogs />} />
                  <Route path="/system-logs/:filter" element={<SystemLogs />} />
                  <Route
                    path="/system-logs/:filter/:filterLabel"
                    element={<SystemLogs />}
                  />
                  <Route path="/data-import" element={<DataImport />} />
                  <Route
                    path="/targeted-practice/manage"
                    element={<TargetedPracticeManager />}
                  />
                  <Route
                    path="/targeted-practice/problems"
                    element={<TargetedPracticeProblems />}
                  />
                </Route>
                <Route
                  element={
                    <Auth allowedRoles={[ROLES.owner, ROLES.sysadmin]} />
                  }
                >
                  <Route path="/users" element={<UsersPage />} />
                  <Route path="/kpi-dashboard" element={<KPIDashboard />} />
                </Route>

                <Route path="/about" element={<About />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditions />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/login" element={<Login />} />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route
                  path="/password-restore/:token"
                  element={<PasswordRestore />}
                />
                <Route path="sign-up" element={<SignUp />} />

                <Route
                  path="*"
                  element={<Navigate to={defaultPagePath} replace />}
                />
              </Routes>
            )}
          </div>
        </div>
        {!isSignUp && scrollButtonVisible && (
          <div className="fixed bottom-5 right-7 z-50 cursor-pointer">
            <ChevronUp
              className="h-10 w-10 p-1 text-white bg-tttDefault rounded-full shadow-lg"
              onClick={() =>
                document.body.scrollTo({ top: 0, behavior: 'smooth' })
              }
            />
          </div>
        )}
        {!isLogin && <Footer />}
        <ToastNotification />
        {showCookieConsent && (
          <CookieConsent
            enableDeclineButton
            buttonStyle={{ borderRadius: '0.5rem' }}
            declineButtonStyle={{ borderRadius: '0.5rem' }}
            buttonText="Accept"
            declineButtonText="Decline"
            onAccept={initGA}
          >
            Please click 'Accept' to consent to the use of cookies for improved
            browsing experience and site traffic analysis. You can also choose
            to 'Decline' cookies.
          </CookieConsent>
        )}
      </div>
    </ErrorBoundary>
  );
};

const ObserverApp = observer(App);

export default ObserverApp;
// export const history = createBrowserHistory({ window });
